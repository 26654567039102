<template>
  <MainContent>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <CustomerAddHeader />
          <CustomerAddForm />
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CustomerAddHeader from '@/components/Modules/Customer/CustomerAdd/CustomerAddHeader'
import CustomerAddForm from '@/components/Modules/Customer/CustomerAdd/CustomerAddForm'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'CustomersAddPage',
  components: {
    MainContent,
    CustomerAddHeader,
    CustomerAddForm,
  },
  mounted() {
    trackEvent(this.$intercom, 'Customer Add')
  },
}
</script>

<style scoped lang="scss"></style>
