<template>
  <div class="header mt-md-5">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="header-title">Add a Customer</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerAddHeader',
}
</script>

<style scoped lang="scss"></style>
