<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-12 mb-4">
          <label>Name</label>
          <input v-model="name" type="text" class="form-control" placeholder="First Last" />
        </div>
        <div class="col-12 mb-4">
          <label>Phone</label>
          <input
            v-model="phone"
            type="text"
            required
            class="form-control"
            placeholder="(000) 000-0000"
            v-mask="'(###) ###-####'"
            autocomplete="tel"
          />
        </div>
        <div class="col-12 mb-0">
          <label>Email</label>
          <input v-model="email" type="text" class="form-control" placeholder="name@example.org" />
        </div>
      </div>
      <hr class="mt-4 mb-4" />
      <CustomerImportLocationDropdown v-on:setLocation="setLocation" />
      <hr class="mt-3 mb-4" />
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>
              Choose an invitation
              <span class="text-muted"></span>
            </label>
            <small class="form-text text-muted"
              >After the customer is added, what would you like to do?</small
            >
            <select class="form-control" v-model="action">
              <option value selected disabled>Choose one</option>
              <option value="none">Do nothing</option>
              <option value="survey">Invite them to leave a survey</option>
            </select>
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-4" />
      <div class="form-group">
        <label class="mb-1">Marketing Eligibility</label>
        <small class="form-text text-muted"
          >Marketing opt-in was previously gathered, making these customers eligible to receive
          campaigns.</small
        >
        <select class="form-control" data-toggle="select" v-model="marketingOptIn">
          <option :value="undefined" selected disabled>Choose one</option>
          <option :value="true">Eligible to receive marketing</option>
          <option :value="false">Not eligible</option>
        </select>
      </div>
      <hr class="mt-3 mb-4" />
      <button
        class="btn btn-lg btn-primary"
        @click="addCustomer"
        :disabled="marketingOptIn === undefined"
      >
        Add Customer
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')
import CustomerService from '@/services/CustomerService'
import CustomerImportLocationDropdown from '../CustomerImport/CustomerImportLocationDropdown'
export default {
  name: 'CustomerAddForm',
  components: {
    CustomerImportLocationDropdown,
  },
  data: () => ({
    action: 'survey',
    locationId: undefined,
    locations: [],
    name: '',
    phone: '',
    email: '',
    marketingOptIn: undefined,
  }),
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
  },
  methods: {
    async addCustomer() {
      try {
        await CustomerService.create({
          name: this.name,
          phone: this.phone,
          email: this.email,
          locationId: this.locationId,
          companyId: this.activeCompany,
          action: this.action,
          marketingOptIn: this.marketingOptIn,
        })

        this.$notify({
          title: 'Success!',
          text: `Customer was added to your database.`,
        })
        this.$router.push({ name: 'customers' })
      } catch (error) {
        this.$notify({
          title: 'Failed to add customer.',
          text: error.body.message,
          type: 'error',
        })
      }
    },
    setLocation(locationId) {
      this.locationId = locationId
    },
  },
}
</script>
